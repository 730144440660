import ColorHash from 'color-hash';
import NewProjectButton from './NewProjectButton';
import PropTypes from 'prop-types';
import React, {useContext, useMemo, useState} from 'react';
import Sidebar, {SidebarButton, SidebarDivider, SidebarFooter} from './Sidebar';
import pluralize from 'pluralize';
import {Box, Center, Flex, Stack, Text} from '@chakra-ui/react';
import {
  FiArchive,
  FiCrop,
  FiMusic,
  FiUser,
  FiUsers,
  FiVideo
} from 'react-icons/fi';
import {Link as GatsbyLink} from 'gatsby';
import {ProjectsContext} from '../utils';
import {readableColor} from 'polished';

const colorHash = new ColorHash();

function ProjectCard({cardProps, title, subtitle, ...props}) {
  return (
    <Flex w="full" align="stretch" lineHeight="1.2" {...props}>
      <Center flexShrink="0" rounded="md" w="10" m="2" {...cardProps} />
      <Box py="3" pl="2" pr="4" textAlign="left" overflow="hidden">
        <Box isTruncated fontFamily="heading" fontSize="lg" fontWeight="medium">
          {title}
        </Box>
        <Flex
          align="center"
          mt="1"
          fontFamily="body"
          fontSize="sm"
          fontWeight="normal"
        >
          {subtitle}
        </Flex>
      </Box>
    </Flex>
  );
}

ProjectCard.propTypes = {
  cardProps: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired
};

export default function ProjectsSidebar() {
  const projects = useContext(ProjectsContext);
  const [sharedShown, setSharedShown] = useState(false);
  const hasSharedProjects = useMemo(
    () => projects.some(project => !project.isOwn),
    [projects]
  );
  return (
    <Sidebar>
      <Box p="2">
        <Stack>
          <SidebarButton
            leftIcon={<FiArchive />}
            isSelected={!sharedShown}
            onClick={() => setSharedShown(false)}
          >
            All projects
          </SidebarButton>
          {hasSharedProjects && (
            <SidebarButton
              leftIcon={<FiUsers />}
              isSelected={sharedShown}
              onClick={() => setSharedShown(true)}
            >
              Shared with me
            </SidebarButton>
          )}
        </Stack>
        <SidebarDivider as={FiMusic} />
        {projects.length ? (
          <Stack>
            {projects
              .filter(project => !sharedShown || !project.isOwn)
              .map(project => {
                const bgColor = colorHash.hex(project.id);
                return (
                  <SidebarButton
                    alignItems="stretch"
                    h="auto"
                    p="0"
                    key={project.id}
                    as={GatsbyLink}
                    to={`/projects/${project.id}`}
                  >
                    <ProjectCard
                      cardProps={{
                        bgColor,
                        color: readableColor(bgColor),
                        children: project.name.charAt(0).toUpperCase()
                      }}
                      title={project.name}
                      subtitle={
                        <>
                          <Box
                            as={
                              project.isOwn
                                ? project.date
                                  ? FiVideo
                                  : FiCrop
                                : FiUser
                            }
                            boxSize="4"
                            mr="2"
                          />
                          {project.isOwn
                            ? project.date
                              ? new Date(project.date).toLocaleDateString()
                              : pluralize('block', project.blocks.length, true)
                            : `by ${project.owner.name}`}
                        </>
                      }
                    />
                  </SidebarButton>
                );
              })}
          </Stack>
        ) : (
          <Box px="2">
            <Box mb="2" textStyle="smallCaps">
              Welcome to Playback 👋
            </Box>
            <Text>
              You haven&apos;t created any projects yet. Click the button below
              to get started.
            </Text>
          </Box>
        )}
      </Box>
      <SidebarFooter>
        <NewProjectButton isFullWidth size="lg" projects={projects} />
      </SidebarFooter>
    </Sidebar>
  );
}
